const autoInit = false;
const config = {"enabled":true,"debug":false,"businessId":"62725984114355859fe34325","autoInit":false,"scriptAsync":true,"scriptURL":"https://storage.googleapis.com/eyezonscriptv2/static/js/eyezonwidget.js","scriptUrl":"https://storage.googleapis.com/eyezonscriptv2/static/js/eyezonwidget.js"};
import { Eyezon } from './eyezon.class'

export default function (context, inject) {
  const $eyezon = new Eyezon()

  if (autoInit) {
    $eyezon.init(config)
  }

  inject('eyezon', $eyezon)
}

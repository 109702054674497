import { unref, watch } from 'vue'

import { getFirstSplitDotName } from '@ga/utils'

import { OPEN_MODAL_TIMEOUT } from '../../constants/queue'
/**
 * Сервис управления очередь очередью модалок при старте приложения
 * @param {Object} gaApp - глобальный объект приложения
 */
export class QueueService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.subscribeInitApp()
    this.subscribeMountedApp()
  }

  get _ftStartQueueModals() {
    return this.gaApp.features.get('hasQueueModals')
  }

  subscribeInitApp(modalId) {
    const unsubscribeMountPage = this.gaApp.eventBus.subscribe(
      'module/app/init',
      () => {
        // при ините приложения ставим флаг ожидания запуска очереди модалок
        if (this._ftStartQueueModals) {
          this.gaApp.stores.modal.queue.pendingFillModals = true
        }

        // отписка от события module/app/init
        unsubscribeMountPage()
      },
    )
  }

  /**
   * Подписка на муант страницы
   * - при срабатывании события module/app/router/page-mounted
   *   - происходит отложенный запуск очереди модалок
   *   - отписка от события module/app/router/page-mounted
   */
  subscribeMountedApp() {
    const unSubscribeInitApp = this.gaApp.eventBus.subscribe(
      'module/app/router/page-mounted',
      () => {
        // отложенный запуск очереди модалок
        if (this._ftStartQueueModals) {
          setTimeout(() => {
            // запускаем очередь модалок
            this.runQueue()
          }, OPEN_MODAL_TIMEOUT)
        }

        // отписка от события module/app/router/page-mounted
        unSubscribeInitApp()
      },
    )
  }

  /**
   * Подписка на событие закрытия модалки
   * - при закрытии модалки
   *   - происходит запуск следующей модалки в очереди
   *   - если очередь модалок закончилась, то
   *     - отписка от события module/modal/register/remove
   */
  subscribeCloseModal() {
    const unSubscribeCloseModal = this.gaApp.eventBus.subscribe(
      'module/modal/register/remove',
      () => {
        // если очередь модалок закончилась
        if (this.gaApp.stores.modal.queue.queueFinished) {
          // отписка от события module/modal/register/remove
          unSubscribeCloseModal()
        }

        // запуск следующей модалки в очереди
        this.nextQueueModal()
      },
    )
  }

  /**
   * Запуск очереди модалок
   * - если очередь модалок пуста, то
   *   - ничего не делаем
   * - иначе
   *   - запускаем следующую модалку в очереди
   *   - подписываемся на событие закрытия модалки
   */
  runQueue() {
    // разблокируем открытие модалок
    this.gaApp.stores.modal.queue.pendingFillModals = false

    if (
      !this.gaApp.stores.modal.queue.hasModals ||
      this.gaApp.stores.modal.queue.queueStarted
    ) {
      return
    }

    this.nextQueueModal()
    this.subscribeCloseModal()
  }

  /**
   * Запускает следующую модалку в очереди
   * - если очередь модалок не пуста и не на паузе и нет открытых зареганых модалок
   */
  nextQueueModal() {
    const { nextModalId, pauseQueue } = this.gaApp.stores.modal.queue
    const hasModals = this.gaApp.stores.modal.register.hasModals

    if (nextModalId && !pauseQueue && !hasModals) {
      this.openQueueModal(nextModalId)
    }

    // если очередь модалок была остановлена
    if (this.gaApp.stores.modal.queue.pauseQueue) {
      // снятие запрета на открытие модалок
      this.removePauseQueue()
    }
  }

  /**
   *   - включает блокировку очереди модалок, если открывается еще одна модалка из подгруппы очереди
   *   - позволяет остановить очередь модалок
   */
  setPauseQueue() {
    this.gaApp.stores.modal.queue.pauseQueue = true
  }

  /**
   *   - убирает блокировку очереди модалок
   *   - позволяет продолжить запуск очереди модалок
   */
  removePauseQueue() {
    this.gaApp.stores.modal.queue.pauseQueue = false
  }

  /**
   *   - открывает модалку из очереди
   *   если она есть в очереди
   * @param {String} modalId - id модалки
   */
  openQueueModal(modalId) {
    if (this.isModalInQueue(modalId)) {
      // если модалки есть  в очереди, то открываем ее
      this.gaApp.stores.modal.queue.openedModals.push(modalId)
    }
  }

  removeQueueModal(modalId) {
    const index = this.gaApp.stores.modal.queue.modals.indexOf(modalId)

    if (index === -1) {
      return
    }

    this.gaApp.stores.modal.queue.modals.splice(index, 1)
  }

  /**
   *   - добавляет модалку в очередь модалок
   *   - если модалка уже была добавлена, то
   *     - ничего не делаем
   * @param {Object} options - опции
   * @param {String} options.modalId - id модалки
   */
  initQueueState({ modalId }) {
    if (this.gaApp.stores.modal.queue.modals.includes(modalId)) {
      return
    }

    this.gaApp.stores.modal.queue.modals.push(modalId)
  }

  /**
   *   - проверяет, есть ли модалка в очереди на открытие
   * @param {String} modalId - id модалки
   * @returns {Boolean} - true, если модалка была открыта
   */
  isModalInQueue(modalId) {
    return this.gaApp.stores.modal.queue.queueModals.includes(modalId)
  }

  /**
   *   - проверяет, была ли модалка открыта после запуска очереди модалок
   * @param {String} modalId - id модалки
   * @returns {Boolean} - true, если модалка была открыта
   */
  wasOpenedModal(modalId) {
    return this.gaApp.stores.modal.queue.openedModals.includes(modalId)
  }

  /**
   * Проверяет, была ли модалка инициализирована в группе
   * @param {String} modalId - id модалки
   * @returns {Boolean} - true, если модалка была инициализирована
   */
  wasInitGroupModalId(modalId) {
    return this.gaApp.stores.modal.queue.groupInitModals.includes(
      getFirstSplitDotName(modalId),
    )
  }

  /**
   * Устанавливает отслеживание состояния модалки (открыта/закрыта)
   * - если модалка была открыта, то
   *   - добавляет модалку в очередь модалок, при старте приложения
   *   - иначе если модалка была открыта повторно и она в очереди, то удаляет модалку из очереди
   *   - иначе если чилдрен модалка открыта из модалки родителя в очереди, то включает блокировку очереди
   * @param {Object} options - опции
   * @param {Boolean} options.opened - состояние модалки (открыта/закрыта)
   * @param {String} options.modalId - id модалки
   */
  setWatchOpenModal({ opened, modalId }) {
    const unwatch = watch(
      opened,
      (value) => {
        if (!value) {
          // если модалка закрыта, то ничего не делаем
          return
        }

        if (this.gaApp.stores.modal.queue.pendingFillModals) {
          // добавляем модалку в очередь модалок
          return this.initQueueState({
            modalId,
          })
        }

        if (this.isModalInQueue(modalId)) {
          // если модалка была инициализирована и уже есть в очереди, то
          // предполагаем что повторно модалку открывает пользователь
          // поэтому ее надо убрать из очереди
          this.removeQueueModal(modalId)
        } else if (this.wasInitGroupModalId(modalId)) {
          // если открывается модалка относящаяся к группе модалок очереди, то
          // включаем блокировку очереди и ждем закрытия модалки и запускаем очередь модалок
          this.setPauseQueue()
        }

        // если очередь закончилась, то удаляем вотчер
        if (this.gaApp.stores.modal.queue.queueFinished) {
          unwatch()
        }
      },
      {
        immediate: true,
      },
    )
  }

  // старт слежения за открытием модалок
  initQueue({ opened, modalId }) {
    if (!this._ftStartQueueModals || unref(opened) === undefined) {
      return
    }

    this.setWatchOpenModal({ opened, modalId })
  }
}

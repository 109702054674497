export const TABLET_CARDS_COUNT_PER_ROW = 4

export const TABLET_CARD_REVERSE_POSITIONS = [2, 3]

export const STREAM_STATUS = {
  LIVE: 'live',
  ARCHIVE: 'archive',
  SOON: 'soon',
}

export const STREAM_TIMER_SIZE = {
  LARGE: 'large',
  SMALL: 'small',
}

export const STREAM_UPDATE_STATUS_INTERVAL = 30000

export const STREAM_LIVE_BASE_URL = 'https://live.goldapple.ru/stream'

export const STREAM_SERVICES = {
  LISTING: 'streams',
  ONE: 'stream',
}

<template>
  <svg
    fill="none"
    stroke="none"
    viewBox="0 0 401 307"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        fill="#DCFF00"
        d="M125.873 118.279.5 306.385V.713l125.373 117.566Z"
        id="reuse-0"
      />
      <path
        fill="#DCFF00"
        d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
        id="reuse-1"
      />
      <path
        fill="#DCFF00"
        d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
        id="reuse-2"
      />
      <path
        fill="#DCFF00"
        d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
        id="reuse-3"
      />
    </defs>
    <g clip-path="url(#gift-cards-envelope-a)">
      <g clip-path="url(#gift-cards-envelope-b)">
        <g filter="url(#gift-cards-envelope-c)" opacity=".6">
          <path
            fill="#859A00"
            d="M130.649 119.014.5 306.385V.713l130.149 118.301Z"
          />
        </g>
        <use xlink:href="#reuse-0" />
        <path
          fill="url(#gift-cards-envelope-d)"
          fill-opacity=".11"
          d="M125.873 118.279.5 306.385V.713l125.373 117.566Z"
        />
        <path
          fill="url(#gift-cards-envelope-e)"
          fill-opacity=".2"
          d="M125.873 118.279.5 306.385V.713l125.373 117.566Z"
        />
        <mask
          id="gift-cards-envelope-h"
          width="126"
          height="307"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-0" />
          <path
            fill="url(#gift-cards-envelope-f)"
            fill-opacity=".11"
            d="M125.873 118.279.5 306.385V.713l125.373 117.566Z"
          />
          <path
            fill="url(#gift-cards-envelope-g)"
            fill-opacity=".2"
            d="M125.873 118.279.5 306.385V.713l125.373 117.566Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-i)"
          opacity=".26"
          mask="url(#gift-cards-envelope-h)"
        >
          <ellipse
            cx="127.707"
            cy="120.673"
            fill="#FFF8B5"
            rx="162.449"
            ry="86.745"
            transform="rotate(41.485 127.707 120.673)"
          />
        </g>
        <use xlink:href="#reuse-1" />
        <path
          fill="url(#gift-cards-envelope-j)"
          d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
        />
        <path
          fill="url(#gift-cards-envelope-k)"
          fill-opacity=".1"
          d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
        />
        <use xlink:href="#reuse-1" />
        <path
          fill="url(#gift-cards-envelope-l)"
          d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
        />
        <path
          fill="url(#gift-cards-envelope-m)"
          fill-opacity=".1"
          d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
        />
        <mask
          id="gift-cards-envelope-p"
          width="126"
          height="307"
          x="275"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-1" />
          <path
            fill="url(#gift-cards-envelope-n)"
            d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
          />
          <path
            fill="url(#gift-cards-envelope-o)"
            fill-opacity=".1"
            d="m275.127 118.279 125.374 188.106V.713L275.127 118.279Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-q)"
          opacity=".13"
          mask="url(#gift-cards-envelope-p)"
        >
          <ellipse
            cx="290.711"
            cy="162.329"
            fill="#95B200"
            rx="137.013"
            ry="92.715"
            transform="rotate(111.976 290.711 162.329)"
          />
        </g>
        <mask
          id="gift-cards-envelope-r"
          width="126"
          height="307"
          x="275"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-1" />
        </mask>
        <g
          filter="url(#gift-cards-envelope-s)"
          opacity=".6"
          mask="url(#gift-cards-envelope-r)"
        >
          <path
            fill="url(#gift-cards-envelope-t)"
            d="M413.634-10.033 264.381 132.056l-16.717-5.97L387.963-3.466l25.671-6.567Z"
          />
        </g>
        <g filter="url(#gift-cards-envelope-u)" opacity=".67">
          <path
            fill="#729A00"
            d="M403.123 306.287H1.812L110.38 127.02a14.709 14.709 0 0 1 12.357-7.086l157.213-2.373a14.707 14.707 0 0 1 12.79 7.068l110.382 181.658Z"
          />
        </g>
        <use xlink:href="#reuse-2" />
        <path
          fill="url(#gift-cards-envelope-v)"
          fill-opacity=".3"
          d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
        />
        <path
          fill="url(#gift-cards-envelope-w)"
          fill-opacity=".19"
          d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
        />
        <g filter="url(#gift-cards-envelope-x)">
          <circle cx="200.5" cy="204.647" r="46.557" fill="#FAFF00" />
        </g>
        <mask
          id="gift-cards-envelope-A"
          width="401"
          height="190"
          x="0"
          y="117"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-2" />
          <path
            fill="url(#gift-cards-envelope-y)"
            fill-opacity=".3"
            d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
          />
          <path
            fill="url(#gift-cards-envelope-z)"
            fill-opacity=".22"
            d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
          />
        </mask>
        <g mask="url(#gift-cards-envelope-A)">
          <g filter="url(#gift-cards-envelope-B)" opacity=".3">
            <path
              fill="#fff"
              d="m-6.713 301.696 128.525-194.754-36.066-21.64-106.885 216.394h14.426Z"
            />
          </g>
          <g filter="url(#gift-cards-envelope-C)" opacity=".7">
            <path
              fill="#fff"
              d="M1.792 304.202s105.574-173.34 109.156-178.713c3.582-5.373 9.552-5.97 16.119-5.97h156.418L88.349 81.907-12.634 301.579l14.426 2.623Z"
            />
          </g>
        </g>
        <g filter="url(#gift-cards-envelope-D)" opacity=".3">
          <ellipse
            cx="267.385"
            cy="310.221"
            fill="#A7C700"
            rx="146.23"
            ry="124.59"
          />
        </g>
        <mask
          id="gift-cards-envelope-G"
          width="401"
          height="190"
          x="0"
          y="117"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-2" />
          <path
            fill="url(#gift-cards-envelope-E)"
            fill-opacity=".3"
            d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
          />
          <path
            fill="url(#gift-cards-envelope-F)"
            fill-opacity=".19"
            d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-H)"
          opacity=".26"
          mask="url(#gift-cards-envelope-G)"
        >
          <ellipse
            cx="127.707"
            cy="120.672"
            fill="#FFF8B5"
            rx="162.449"
            ry="86.745"
            transform="rotate(41.485 127.707 120.672)"
          />
        </g>
        <mask
          id="gift-cards-envelope-K"
          width="401"
          height="190"
          x="0"
          y="117"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-2" />
          <path
            fill="url(#gift-cards-envelope-I)"
            fill-opacity=".3"
            d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
          />
          <path
            fill="url(#gift-cards-envelope-J)"
            fill-opacity=".19"
            d="M400.5 306.384H.5l108.521-181.497a14.705 14.705 0 0 1 12.622-7.159h157.714c5.174 0 9.967 2.718 12.622 7.159L400.5 306.384Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-L)"
          opacity=".13"
          mask="url(#gift-cards-envelope-K)"
        >
          <ellipse
            cx="290.711"
            cy="162.328"
            fill="#95B200"
            rx="137.013"
            ry="92.715"
            transform="rotate(111.976 290.711 162.328)"
          />
        </g>
      </g>
      <g clip-path="url(#gift-cards-envelope-M)">
        <g filter="url(#gift-cards-envelope-N)" opacity=".46">
          <path
            fill="#729A00"
            d="M.5.713h400V24.01c0 3.914-1.56 7.666-4.335 10.427L225.997 203.69c-11.344 11.284-29.627 11.429-41.149.326L5.002 30.723A14.706 14.706 0 0 1 .5 20.132V.713Z"
          />
        </g>
        <g filter="url(#gift-cards-envelope-O)" opacity=".16">
          <path
            fill="#859A00"
            d="M-9.052.713h384.477v23.775c0 3.676-1.376 7.219-3.859 9.93L217.306 202.92c-11.172 12.204-30.209 12.794-42.116 1.305L-4.558 30.79a14.706 14.706 0 0 1-4.494-10.583V.713Z"
          />
        </g>
        <use xlink:href="#reuse-3" />
        <path
          fill="url(#gift-cards-envelope-P)"
          fill-opacity=".31"
          d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
        />
        <path
          fill="url(#gift-cards-envelope-Q)"
          fill-opacity=".6"
          d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
        />
        <mask
          id="gift-cards-envelope-S"
          width="401"
          height="208"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <path
            fill="#DCFF00"
            d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.892 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
          />
          <path
            fill="url(#gift-cards-envelope-R)"
            fill-opacity=".11"
            d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.892 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-T)"
          mask="url(#gift-cards-envelope-S)"
        >
          <path
            fill="url(#gift-cards-envelope-U)"
            d="m220.172 240.057 4.59-2.623-89.836-83.934L34.598 63.008S9.025 37.434 5.09 32.844C1.156 28.254 2.467-9.123 2.467-9.123l-13.77 37.377 231.475 211.803Z"
          />
        </g>
        <mask
          id="gift-cards-envelope-X"
          width="401"
          height="208"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-3" />
          <path
            fill="url(#gift-cards-envelope-V)"
            fill-opacity=".31"
            d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
          />
          <path
            fill="url(#gift-cards-envelope-W)"
            fill-opacity=".6"
            d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-Y)"
          opacity=".26"
          mask="url(#gift-cards-envelope-X)"
        >
          <ellipse
            cx="127.707"
            cy="120.673"
            fill="#FFF8B5"
            rx="162.449"
            ry="86.745"
            transform="rotate(41.485 127.707 120.673)"
          />
        </g>
        <mask
          id="gift-cards-envelope-ab"
          width="401"
          height="208"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style="mask-type: alpha"
        >
          <use xlink:href="#reuse-3" />
          <path
            fill="url(#gift-cards-envelope-Z)"
            fill-opacity=".31"
            d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
          />
          <path
            fill="url(#gift-cards-envelope-aa)"
            fill-opacity=".6"
            d="M.5.713h400v23.182c0 4.028-1.652 7.88-4.571 10.656L222.283 199.698c-11.953 11.367-30.891 10.646-42.434-1.138C119.513 136.96 59.208 93.609 4.354 34.4A14.546 14.546 0 0 1 .5 24.512V.712Z"
          />
        </mask>
        <g
          filter="url(#gift-cards-envelope-ac)"
          opacity=".13"
          mask="url(#gift-cards-envelope-ab)"
        >
          <ellipse
            cx="296.248"
            cy="164.563"
            fill="#95B200"
            rx="137.013"
            ry="86.745"
            transform="rotate(111.976 296.248 164.563)"
          />
        </g>
      </g>
      <g clip-path="url(#gift-cards-envelope-ad)">
        <rect
          width="71.642"
          height="71.642"
          x="164.082"
          y="168.582"
          fill="#000"
          rx="35.821"
        />
        <path
          fill="#DCFF00"
          d="M196.698 210.058c-.377.734-1.673 2.762-5.954 3.909l-.424.114c-7.511 2.012-10.342-1.541-11.118-4.438l-.139-.517c-.791-2.952-.182-7.249 7.901-9.415l.719-.193c8.397-2.249 10.483.811 11.052 2.715l.005.019-5.462 1.463c-.2-.302-1.037-1.581-4.912-.543-3.691.989-4.318 2.858-3.833 4.667l.054.203c.47 1.753 2.222 3.123 5.636 2.208 3.543-.949 4.346-2.529 4.227-2.972l-4.816 1.291-.717-2.676 10.168-2.725 2.071 7.732-3.303.885c-.197-.441-.763-1.298-1.155-1.727Zm19.555-3.203-1.664-1.927-7.843 2.101-.458 2.496-5.315 1.424 3.112-15.549 7.049-1.888 10.858 11.805-5.739 1.538Zm-8.821-3.688 4.558-1.222-3.573-4.106-.985 5.328Z"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="gift-cards-envelope-d"
        x1="81.62"
        x2="-67.124"
        y1="71.252"
        y2="479.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-e"
        x1="9.455"
        x2="97.678"
        y1="329.668"
        y2="326.815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BDFB0D" />
        <stop offset="1" stop-color="#BDFB0D" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-f"
        x1="81.62"
        x2="-67.124"
        y1="71.252"
        y2="479.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-g"
        x1="9.455"
        x2="97.678"
        y1="329.668"
        y2="326.815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BDFB0D" />
        <stop offset="1" stop-color="#BDFB0D" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-j"
        x1="152.739"
        x2="415.03"
        y1="37.728"
        y2="228.719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A5BE04" />
        <stop offset=".611" stop-color="#CDED01" stop-opacity=".26" />
        <stop offset="1" stop-color="#D4F500" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-k"
        x1="414.232"
        x2="430.662"
        y1="-18.989"
        y2="1.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#90BF0A" />
        <stop offset="1" stop-color="#BDFF00" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-l"
        x1="152.739"
        x2="415.03"
        y1="37.728"
        y2="228.719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A5BE04" />
        <stop offset=".611" stop-color="#CDED01" stop-opacity=".26" />
        <stop offset="1" stop-color="#D4F500" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-m"
        x1="414.232"
        x2="430.662"
        y1="-18.989"
        y2="1.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#90BF0A" />
        <stop offset="1" stop-color="#BDFF00" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-n"
        x1="152.739"
        x2="415.03"
        y1="37.728"
        y2="228.719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A5BE04" />
        <stop offset=".611" stop-color="#CDED01" stop-opacity=".26" />
        <stop offset="1" stop-color="#D4F500" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-o"
        x1="414.232"
        x2="430.662"
        y1="-18.989"
        y2="1.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#90BF0A" />
        <stop offset="1" stop-color="#BDFF00" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-t"
        x1="293.037"
        x2="479.903"
        y1="105.191"
        y2="-64.362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-v"
        x1="254.27"
        x2="146.371"
        y1="381.04"
        y2="137.265"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A1DA00" />
        <stop offset="0" stop-color="#AFEA0A" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-w"
        x1="127.057"
        x2="192.631"
        y1="162.68"
        y2="371.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".074" stop-color="#fff" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-y"
        x1="264.107"
        x2="146.393"
        y1="365.959"
        y2="137.279"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A1DA00" />
        <stop offset="0" stop-color="#AFEA0A" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-z"
        x1="117.221"
        x2="195.254"
        y1="175.139"
        y2="362.024"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".074" stop-color="#fff" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-E"
        x1="254.27"
        x2="146.371"
        y1="381.04"
        y2="137.265"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A1DA00" />
        <stop offset="0" stop-color="#AFEA0A" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-F"
        x1="127.057"
        x2="192.631"
        y1="162.68"
        y2="371.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".074" stop-color="#fff" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-I"
        x1="254.27"
        x2="146.371"
        y1="381.04"
        y2="137.265"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A1DA00" />
        <stop offset="0" stop-color="#AFEA0A" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-J"
        x1="127.057"
        x2="192.631"
        y1="162.68"
        y2="371.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".074" stop-color="#fff" />
        <stop offset="1" stop-color="#A3F61B" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-P"
        x1="89.68"
        x2="240.379"
        y1="-7.812"
        y2="-21.313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-R"
        x1="35.127"
        x2="198.448"
        y1=".713"
        y2="-82.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-U"
        x1="143.451"
        x2="-51.959"
        y1="162.68"
        y2="-26.172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-V"
        x1="89.68"
        x2="240.379"
        y1="-7.812"
        y2="-21.313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="gift-cards-envelope-Z"
        x1="89.68"
        x2="240.379"
        y1="-7.812"
        y2="-21.313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <filter
        id="gift-cards-envelope-c"
        width="138.973"
        height="314.495"
        x="-3.912"
        y="-3.699"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="2.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-i"
        width="478.046"
        height="460.28"
        x="-111.316"
        y="-109.467"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="52.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-q"
        width="409.066"
        height="472.309"
        x="86.178"
        y="-73.826"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="52.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-s"
        width="171.216"
        height="147.335"
        x="245.041"
        y="-12.656"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="1.311"
        />
      </filter>
      <filter
        id="gift-cards-envelope-u"
        width="410.135"
        height="197.551"
        x="-2.6"
        y="113.148"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="2.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-x"
        width="310.82"
        height="310.82"
        x="45.09"
        y="49.237"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="54.426"
        />
      </filter>
      <filter
        id="gift-cards-envelope-B"
        width="245.246"
        height="318.689"
        x="-72.287"
        y="34.155"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="25.574"
        />
      </filter>
      <filter
        id="gift-cards-envelope-C"
        width="301.365"
        height="227.541"
        x="-15.257"
        y="79.284"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="1.311"
        />
      </filter>
      <filter
        id="gift-cards-envelope-D"
        width="743.607"
        height="700.328"
        x="-104.418"
        y="-39.943"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="112.787"
        />
      </filter>
      <filter
        id="gift-cards-envelope-H"
        width="478.046"
        height="460.28"
        x="-111.316"
        y="-109.467"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="52.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-L"
        width="409.066"
        height="472.309"
        x="86.178"
        y="-73.826"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="52.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-N"
        width="414.328"
        height="225.865"
        x="-6.664"
        y="-6.451"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="3.582"
        />
      </filter>
      <filter
        id="gift-cards-envelope-O"
        width="408.358"
        height="235.639"
        x="-20.992"
        y="-11.227"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="5.97"
        />
      </filter>
      <filter
        id="gift-cards-envelope-T"
        width="246.557"
        height="259.672"
        x="-16.549"
        y="-14.369"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="2.623"
        />
      </filter>
      <filter
        id="gift-cards-envelope-Y"
        width="478.046"
        height="460.28"
        x="-111.316"
        y="-109.467"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="52.206"
        />
      </filter>
      <filter
        id="gift-cards-envelope-ac"
        width="399.634"
        height="471.164"
        x="96.431"
        y="-71.019"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_1817_28529"
          stdDeviation="52.206"
        />
      </filter>
      <clipPath id="gift-cards-envelope-a">
        <path
          fill="#fff"
          d="M0 0h400v305.574H0z"
          transform="translate(.5 .713)"
        />
      </clipPath>
      <clipPath id="gift-cards-envelope-b">
        <path
          fill="#fff"
          d="M.5 5.489A4.776 4.776 0 0 1 5.276.713h390.448a4.776 4.776 0 0 1 4.776 4.776v296.119a4.776 4.776 0 0 1-4.776 4.777H5.276A4.777 4.777 0 0 1 .5 301.608V5.489Z"
        />
      </clipPath>
      <clipPath id="gift-cards-envelope-M">
        <path
          fill="#fff"
          d="M.5 5.489A4.776 4.776 0 0 1 5.276.713h390.448a4.776 4.776 0 0 1 4.776 4.776v219.702H.5V5.489Z"
        />
      </clipPath>
      <clipPath id="gift-cards-envelope-ad">
        <path
          fill="#fff"
          d="M0 0h71.642v71.642H0z"
          transform="translate(164.082 168.582)"
        />
      </clipPath>
      <radialGradient
        id="gift-cards-envelope-Q"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(.662 -3371.04 7161.603) scale(283.953 226.259)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FDFF9E" stop-opacity=".3" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="gift-cards-envelope-W"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(.662 -3371.04 7161.603) scale(283.953 226.259)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FDFF9E" stop-opacity=".3" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="gift-cards-envelope-aa"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(.662 -3371.04 7161.603) scale(283.953 226.259)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FDFF9E" stop-opacity=".3" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-envelope',
}
</script>

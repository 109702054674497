export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async fetchOrdersListingV3(data = {}) {
    const { url } = this.endpoints.ordersListingV3()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchFiltersV3(data = {}) {
    const { url } = this.endpoints.filtersOrdersV3()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchOrdersV3(data = {}) {
    const { url } = this.endpoints.ordersV3()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchOrderDetailV3(id) {
    const params = { id }

    const { url } = this.endpoints.orderDetailV3()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async canCancelOrderV3(id) {
    const params = { id }
    const { url } = this.endpoints.canCancelOrderV3()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async cancelOrderV3(id) {
    const params = { id }
    const { url } = this.endpoints.cancelOrderV3()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getChangeDeliveryInfo(id) {
    const params = { id }
    const { url } = this.endpoints.getChangeDeliveryInfo()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async onDemandOrderV3(id) {
    const params = { id }
    const { url } = this.endpoints.onDemandOrderV3()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async onDemandOrderDetailV3(id) {
    const params = { id }
    const { url } = this.endpoints.onDemandOrderDetailV3()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getProfileInfo() {
    const { url } = this.endpoints.getProfileInfo()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async saveProfileInfo(data) {
    const { url } = this.endpoints.saveProfileInfo()

    try {
      const response = await this.gaApp.api.request().put(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getInfo() {
    const { url } = this.endpoints.getInfo()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async updateInfo(data) {
    const { url } = this.endpoints.updateInfo()

    try {
      const response = await this.gaApp.api.request().put(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async setSettings(data = {}) {
    const { url } = this.endpoints.getSettings()

    try {
      const response = await this.gaApp.api.request().put(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async emailConfirm() {
    const { url } = this.endpoints.emailConfirm()

    try {
      const response = await this.gaApp.api.request().post(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getLoyalty() {
    const { url } = this.endpoints.getLoyalty()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getSubscriptionsSettings() {
    const { url } = this.endpoints.subscriptionsSettings()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async updateSubscriptionsSettings(data) {
    const { url } = this.endpoints.subscriptionsSettings()

    try {
      const response = await this.gaApp.api.request().put(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getChangeStorageInfo(id) {
    const params = { id }
    const { url } = this.endpoints.getChangeStorageInfo()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
